import Collapse from 'styleguide/components/Collapse/Collapse';
import * as React from 'react';

const Content = () => (
  <div className="my-2 space-y-2">
    <div>
      <h6 className="font-hvBold text-lg">Time</h6>
      <div>
        The earlier it is in the day, the more shipping options you’ll have, which means you get your project
        even faster. The sooner we know about your project, the better we can help.
      </div>
    </div>
    <div>
      <h6 className="font-hvBold text-lg">Volume</h6>
      <div>
        A tight schedule may affect options we can offer. Your Rush Critical representative will accommodate
        all the possibilities and recommend a solution for you.
      </div>
    </div>
    <div>
      <h6 className="font-hvBold text-lg">Product</h6>
      <div>
        Every quote is based on a variety of factors: Dimension, paper, printing, binding, and more. Check our
        product pages and use the quote tool to see how these options can affect your project budget. And
        don’t forget we’re always here to help.
      </div>
    </div>
    <div>
      <h6 className="font-hvBold text-lg">Shipping</h6>
      <div>
        Rush Critical orders get access to our vast network of shipping partners, each with their own
        logistics that should be considered. Your Rush Critical representative will help you find the best
        fit.
      </div>
    </div>
  </div>
);
const Guidelines = () => (
  <Collapse className="border-t-2 border-gray-50 py-4">
    <Collapse.Item
      expanded
      title={
        <h3 className="mb-2 font-hvBold text-2xl lg:text-4xl">
          Factors That May Affect Production And Shipping
        </h3>
      }
      body={<Content />}
    />
  </Collapse>
);

export default Guidelines;
