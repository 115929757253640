import * as React from 'react';
import TwoColumnHero from 'bundles/App/pages/service/TwoColumnHero/TwoColumnHero';
import Features from 'bundles/App/pages/service/Features/Features';
import GoRushCritical from 'bundles/App/pages/service/GoRushCritical/GoRushCritical';
import Guidelines from './Guidelines/Guidelines';
import Footer from 'styleguide/components/Footer/Footer';
import Meta from 'styleguide/components/Meta/Meta';
import FAQ from 'bundles/App/pages/ProductDetailsPage/AdditionalInfo/FAQ/FAQ';
import AdditionalInfo from 'bundles/App/pages/ProductDetailsPage/AdditionalInfo/AdditionalInfo';
import { Faq } from 'bundles/App/pages/HelpCenter/types';

const faqs: Faq[] = [
  {
    question: 'How Do I Know My Order Has Been Received?',
    answer:
      'Once you’ve emailed us the file for 24-hour printing with all details for the order (desired products, specs and quantity), our team will perform a quality check and let you know of any issues as soon as possible. Our team will then create a proof and you’ll receive a confirmation email from our team with a copy for your approval. We will not proceed with your order till the proof has been approved.',
  },
  {
    question: 'Do You Provide a Tracking Number for Same Day Printing Orders?',
    answer:
      'As part of our 24-hr printing services, you will know exactly what’s happening with your order from start to finish. Once your order is picked up by our shipping partners, you will receive an email containing a full list of details about the shipping order and its tracking information.',
  },
  {
    question: 'How Do I Send You My Files?',
    answer:
      'Getting your files to us for same day printing is easy — no need to upload documents online, simply send your file to Service@Printivity.com. You may also choose to call us at 1-877-649-5463 if you prefer to speak to someone about your order. If you have any issues placing your order for overnight printing services, contact our customer service team for direct assistance.',
  },
  {
    question: 'Can I See a Preview Before I Place The Order?',
    answer:
      'After our pre-press team performs a thorough check of your files to make them print-ready, you will receive a digital proof for approval of your 24-hour print project. You won’t be required to pay for same day prints till after you have approved the proof. Please note that approval is required for the order to move into production.',
  },
  {
    question: 'How Can I Change My Same Day Printing Order?',
    answer:
      'If you want to change your 24-hour printing services order, please contact us as soon as possible after placing the order. If you uploaded the wrong files or need to make last-minute updates, it’s possible that we will be able to make changes to overnight print orders that haven’t yet been printed or placed into production. We’ll always do our best to check, but any changes to your order after payment are not guaranteed or may cause delays.',
  },
  {
    question: 'Which Payment Methods Do You Accept?',
    answer:
      'We accept American Express, Visa, Mastercard, and Discover for same day booklet printing and any of our other services. If you’re having payment issues, please contact us directly for additional support.',
  },
  {
    question: 'What Happens After I Place My Order?',
    answer:
      'After you email us your files for a new Rush Critical order, our team of experts will perform a thorough quality check and reach out immediately if there are any issues with your files. To provide you with the best customer experience, you will also receive a digital proof that you’ll need to approve before we move forward with the order. Once the proof has been approved, our print/bindery services will sign off on whether they’ll be able to complete the order within the desired timeframe. After they’ve confirmed, a quote will be calculated and we’ll get back to you with the final price and timeline. Then, you’ll pay online and your overnight printing services order will go into production. Once it’s ready, it’ll be shipped out and delivered straight to your door. It’s really that easy! ',
  },
  {
    question: 'How Much is Shipping for Same Day Printing?',
    answer:
      'Your shipping price for same day printing depends not just on the speed of the delivery but also the quantity, page count, dimension, paper type, and delivery location.',
  },
];

const RushCritical = () => (
  <>
    <Meta
      title="Same Day Printing Services: Overnight & 24-Hour Rush | Printivity"
      description="Need overnight prints? Grow your business, market your company, & promote your brand with affordable same day & 24-hour printing services. ✓ Print yours!"
      canonical="https://www.printivity.com/rush-critical"
    />
    <TwoColumnHero />
    <Features />
    <GoRushCritical />
    <Guidelines />
    <AdditionalInfo FAQ={<FAQ questions={faqs} />} />
    <Footer />
  </>
);

export default RushCritical;
