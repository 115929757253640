import * as React from 'react';
import Grid from 'styleguide/components/Grid/Grid';
import RushActionButton from 'bundles/App/pages/service/RushActionButton/RushActionButton';
import placeholderImg from 'assets/images/rush_critical/rush-critical.jpg';
import { H1 } from 'styleguide/components/Heading';

const TwoColumnHero = () => (
  <Grid.Container className="bg-no-repeat bg-contain min-h-[700px]">
    <Grid fullWidth>
      <Grid.Row className="lg:min-h-[700px]">
        <Grid.Col sm={12} lg={6} className="bg-blue !flex !flex-col !items-center !justify-center">
          <div className="-lg:mt-5">
            <H1 className="text-center mx-[5%] !text-white sm:!text-5xl lg:!text-7xl xl:!text-8xl">
              Get Your Prints Within 24 Hours.
            </H1>
          </div>
          <div className="my-[2.3em]">
            <p className="text-white text-base leading-none text-center md:text-lg">
              Same day printing, overnight printing, and next day printing services.
            </p>
          </div>
          <RushActionButton />
        </Grid.Col>
        <Grid.Col
          sm={12}
          lg={6}
          className="bg-cover bg-no-repeat min-h-[425px] bg-center lg:min-h-auto lg:bg-inherit"
          style={{
            backgroundImage: `url(${placeholderImg})`,
          }}
        />
      </Grid.Row>
    </Grid>
  </Grid.Container>
);

export default TwoColumnHero;
