import * as React from 'react';
import Grid from 'styleguide/components/Grid/Grid';
import H3 from 'styleguide/components/Heading/H3';
import Span from 'styleguide/components/Span/Span';
import UL, { LI } from 'styleguide/components/UL/UL';

const Features = () => (
  <Grid.Container className="bg-additional-graySmoke py-8">
    <Grid>
      <Grid.Row>
        <Grid.Col md={2} />
        <Grid.Col sm={12} md={8} className="!flex !justify-center md:!justify-start -md:pl-12">
          <H3>Printivity Rush Critical is the Absolute Fastest Online Printing Service in the USA.</H3>
        </Grid.Col>
        <Grid.Col md={2} />
      </Grid.Row>
      <Grid.Row>
        <Grid.Col sm={1} md={2} />
        <Grid.Col sm={10} md={8} className="!flex !justify-start">
          <UL>
            <LI iconSize="small" iconColor="orange">
              <Span>
                Work with a dedicated Rush Critical expert that will guide you through the ordering process.
              </Span>
            </LI>
            <LI iconSize="small" iconColor="orange">
              <Span>Get highest priority status as your order moves through the production system.</Span>
            </LI>
            <LI iconSize="small" iconColor="orange">
              <Span>Receive a custom shipment that is guaranteed to arrive by your deadline.</Span>
            </LI>
            <LI iconSize="small" iconColor="orange">
              <Span>Watch your order make its way to you with real-time tracking updates.</Span>
            </LI>
          </UL>
        </Grid.Col>
        <Grid.Col sm={1} md={2} />
      </Grid.Row>
    </Grid>
  </Grid.Container>
);

export default Features;
