import * as React from 'react';
import Grid from 'styleguide/components/Grid/Grid';
import Span from 'styleguide/components/Span/Span';
import RushActionButton from 'bundles/App/pages/service/RushActionButton/RushActionButton';

interface BlockProps {
  number: string;
  title: string;
  description: string;
}

const Block = ({ title, description, ...props }: BlockProps) => (
  <Grid.Col
    sm={12}
    md={12}
    lg={4}
    xlg2={4}
    className="!flex !items-center !justify-center md:pl-3 lg:items-baseline"
  >
    <Span className="!font-hvBold !text-7xl">{props.number}</Span>
    <div className="ml-4 lg:max-w-[220px]">
      <Span className="block mb-3 !text-lg !font-hvMedium">{title}</Span>
      <Span>{description}</Span>
    </div>
  </Grid.Col>
);

const GoRushCritical = () => (
  <Grid.Container className="my-8">
    <Grid>
      <Grid.Row>
        <Grid.Col sm={12}>
          <h3 className="flex justify-center text-center font-hvMedium text-5xl lg:text-7xl">
            How To Get Rush Critical Service
          </h3>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row className="text-default">
        <Grid.Col className="my-4 inline-flex items-center">
          <RushActionButton callColor="black" />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row className="text-default">
        <Block
          number="1"
          title="Review a proof"
          description="You’ll receive an email with a digital proof that you approve before the magic really happens."
        />
        <Block
          number="2"
          title="Get a quote"
          description="We’ll custom tailor a production and shipping schedule based on your needs and budget."
        />
        <Block
          number="3"
          title="Get your project"
          description="Your order is blasted through production so you get your prints on-time, guaranteed."
        />
      </Grid.Row>
    </Grid>
  </Grid.Container>
);

export default GoRushCritical;
