import * as React from 'react';
import IfMobile from 'styleguide/components/Conditional/IfMobile';
import A from 'styleguide/components/Links/A';
import ButtonAsNativeLink from 'styleguide/components/Button/ButtonAsNativeLink';
import IfTabletOrDesktop from 'styleguide/components/Conditional/IfTabletOrDesktop';
import { Color } from 'styleguide/components/Links/types';

interface RushActionProps {
  callColor?: Color | 'white';
}

const RushActionButton = ({ callColor }: RushActionProps) => (
  <>
    <IfMobile>
      <div className="mb-4">
        <div>
          <A href="tel:18776495463" color={callColor} underline="always">
            Call 1-877-649-5463
          </A>
        </div>
        <ButtonAsNativeLink
          buttonType="link"
          color="orange"
          target="/contact-us"
          className="text-sm mt-4 px-1 md:text-base"
        >
          Email Us Now For Rush Critical Quote
        </ButtonAsNativeLink>
      </div>
    </IfMobile>
    <IfTabletOrDesktop>
      <div className="mb-4">
        <ButtonAsNativeLink
          buttonType="link"
          color="orange"
          target="/contact-us"
          className="text-sm mt-4 px-1 md:text-base"
        >
          Call 1-877-649-5463 or Email Us Now For Rush Critical Quote
        </ButtonAsNativeLink>
      </div>
    </IfTabletOrDesktop>
  </>
);

export default RushActionButton;
